define("ember-cli-clipboard/components/copy-button", ["exports", "@ember/component", "@ember/object", "@ember/object/internals", "ember-cli-clipboard/templates/components/copy-button", "clipboard"], function (_exports, _component, _object, _internals, _copyButton, _clipboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const CLIPBOARD_EVENTS = ['success', 'error'];
  let CopyButtonComponent = (_class = class CopyButtonComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "layout", _copyButton.default);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "delegateClickEvent", true);
    }

    /**
     * Assigns button element an id
     * @returns {Void}
     */
    setupElement(element) {
      element.id = (0, _internals.guidFor)(this);
      this._buttonElement = element;
    }
    /**
     * Registers ClipboardJS object with component
     * @private
     * @returns {Void}
     */


    registerClipboard() {
      if (this.clipboard) {
        this.clipboard.destroy();
      }

      const clipboard = this._createClipboard();

      this._registerActions(clipboard);

      this.clipboard = clipboard;
    }
    /**
     * Destroys `ClipboardJS` instance
     * @returns {Void}
     */


    destroyClipboard() {
      if (this.clipboard) {
        this.clipboard.destroy();
      }
    }
    /**
     * Creates new `ClipboardJS` instance
     * @private
     * @returns {Object} newly created ClipboardJS object
     */


    _createClipboard() {
      const {
        clipboardText: text,
        container,
        delegateClickEvent
      } = this;
      const trigger = delegateClickEvent === false ? this._buttonElement : `#${this._buttonElement.id}`;
      return new _clipboard.default(trigger, {
        text: typeof text === 'function' ? text : undefined,
        container: typeof container === 'string' ? document.querySelector(container) : container
      });
    }
    /**
     * Registers Ember Actions with ClipboardJS events
     * @private
     * @param {Object} clipboard - ClipboardJS object
     * @returns {Void}
     */


    _registerActions(clipboard) {
      CLIPBOARD_EVENTS.forEach(event => {
        clipboard.on(event, () => {
          if (!this._buttonElement.disabled) {
            const action = this[event];

            if (typeof action === 'string') {
              // eslint-disable-next-line ember/closure-actions
              this.sendAction(action, ...arguments);
            } else {
              action && action(...arguments);
            }
          }
        });
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "setupElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerClipboard", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerClipboard"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "destroyClipboard", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "destroyClipboard"), _class.prototype)), _class);
  _exports.default = CopyButtonComponent;
});