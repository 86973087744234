define("ember-cli-workbox/instance-initializers/sw", ["exports", "@ember/debug", "@ember/object"], function (_exports, _debug, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getConfig = getConfig;
  _exports.initialize = initialize;

  function getWithDefault(obj, path, def) {
    const value = (0, _object.get)(obj, path);
    return typeof value !== 'undefined' ? value : def;
  }

  function getConfig(appInstance) {
    const config = appInstance.resolveRegistration('config:environment');
    const isProdBuild = config.environment === 'production';
    return {
      isEnabled: getWithDefault(config, 'ember-cli-workbox.enabled', isProdBuild),
      debugAddon: getWithDefault(config, 'ember-cli-workbox.debug', !isProdBuild),
      swDestFile: getWithDefault(config, 'ember-cli-workbox.swDest', 'sw.js'),
      autoRegister: getWithDefault(config, 'ember-cli-workbox.autoRegister', true)
    };
  }

  function initialize(appInstance) {
    const swService = appInstance.lookup('service:service-worker');
    const {
      isEnabled,
      debugAddon,
      swDestFile
    } = getConfig(appInstance);
    swService.set('debug', debugAddon); // first checks whether the browser supports service workers

    if (swService.get('isSupported')) {
      // Load and register pre-caching Service Worker
      if (isEnabled) {
        swService.register(swDestFile);
      } else {
        swService.unregisterAll();
      }
    } else {
      (0, _debug.debug)('Service workers are not supported in this browser.');
    }
  }

  var _default = {
    name: 'ember-cli-workbox',

    initialize(appInstance) {
      const {
        autoRegister
      } = getConfig(appInstance);

      if (autoRegister) {
        initialize(appInstance);
      }
    }

  };
  _exports.default = _default;
});