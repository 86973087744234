define("ember-get-config/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let config;
  {
    config = undefined;
  }
  var _default = config;
  _exports.default = _default;
});