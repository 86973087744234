define("ember-sharer/modifiers/sharer-init", ["exports", "ember-modifier", "ember-sharer/sharer-lib"], function (_exports, _emberModifier, _sharerLib) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)(function sharerInit(element
  /*, params, hash*/
  ) {
    element.addEventListener('click', () => new _sharerLib.default(element).share());
  });

  _exports.default = _default;
});